import React from "react"
//import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
//import * as dashboardStyles from "../../styles/page/dashboard.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"

// Chartコンポーネント
import SearchFilter from "../../components/parts/chart/search-filter"
import SankyModal from "../../components/parts/modal"


// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false
    }

    this.searchInit = {
      filter1Init: 0,
      filter2Init: 0,
      filter3Init: 'market-trend'
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    this.setState({modalOpen : true})
  }
  closeModal(e) {
    this.setState({modalOpen : false})
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading}>{title}</h1>
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          <h2 className={styles.pageContentsHeading2}>本ダッシュボードについて</h2>
          <p className="pb-3 leading-7">グリーンイノベーション基金事業の関連産業や技術に関する有益な情報を分かりやすく整理したサイトです。掲載されているデータは公表情報をもとにNEDOでとりまとめたものです。各データの詳細についてはそれぞれの出所をご参照ください。特に表記のない限り、出所に「作成」とある図表は出所の情報を基にNEDOが作成したものです。</p>

          <h3 className={styles.pageContentsHeading3}>本ダッシュボードの使い方</h3>
          <ul>
            <li>上部のフィルタで「重点分野」「個別分野」「区分」を選択し、「表示」ボタンをクリックすると、該当区分の図表が表示されます。</li>
            <li>一部、プルダウンにより表示するデータの変更ができる図表や、データのダウンロードができる図表もあります。</li>
          </ul>

          <h3 className={styles.pageContentsHeading3}>国際エネルギー機関（IEA）による将来見通しのシナリオ</h3>
          <ul>
            <li>NZE（Net Zero Emission）：ネットゼロシナリオ（2050年に世界全体でネットゼロを達成するためのシナリオ）</li>
            <li>SDS（Sustainable Development Scenario）：持続可能な開発シナリオ（パリ協定で定められた目標を完全に達成するために、どのような道筋をたどるべきかを分析したシナリオ）</li>
            <li>APS（Announced Pledge Scenario）：発表誓約シナリオ（各国が宣言した野心を反映したシナリオ）</li>
            <li>STEPS（Stated Policy Scenario）：公表政策シナリオ（各国が表明済みの具体的政策を反映したシナリオ）</li>
          </ul>

          <h3 className={styles.pageContentsHeading3}>IEA NZEにおけるエネルギーバランス・フロー概要（2050年）</h3>
          <div className="pb-5">
            <div>
              <button className={styles.modalLink} onClick={this.openModal}>
                <img src="/images/chart/sanky-1.svg" alt="IEA NZEにおけるエネルギーバランス・フロー概要（2050年）" width="100%" height="auto" />
              </button>
              <SankyModal
                open={this.state.modalOpen}
                close={this.closeModal}
                src={"/images/chart/sanky-1.svg"}
                alt={"IEA NZEにおけるエネルギーバランス・フロー概要（2050年）"}
                style={{width : '100%', height: 'auto'}}
              />
            </div>
          </div>
          <ul>
            <li>IEA NZEでは2050年に電化率（最終エネルギー消費に占める電気の割合）が49%と2020年の20%から約2.5倍となる。発電部門は再生可能エネルギーの割合が2050年に88%まで拡大し、バイオマスCCS等の活用と併せて、CO<sub>2</sub>排出がマイナスとなる。</li>
            <li>水素の生産量は2050年に5.28億tとなり、そのうち6割強が水電解、4割弱が化石燃料＋CCSによる。水素ベースの燃料は輸送部門、産業部門、発電部門を中心に全部門で活用され、カーボンニュートラル達成に大きく貢献。</li>
            <li>CO<sub>2</sub>回収量は2050年に76億tCO<sub>2</sub>となり、そのうち66億tCO<sub>2</sub>が固定排出源からの回収、10億tCO<sub>2</sub>が大気回収（DACCS）。</li>
          </ul>
          <p className="pb-3 leading-7">出所）<a href="https://www.iea.org/reports/net-zero-by-2050" className={styles.linkText} target="_blank" rel="noreferrer noopener">IEA, "Net Zero by 2050"</a>（2021年5月）を基に作成<br />
          IEAネットゼロシナリオにおける2050年の世界全体の一次エネルギー供給から最終エネルギー消費までのエネルギーの流れを示したもの。<br />
          各項目の投入と産出の差分を損失として集計しており、原典に記載されている情報の制約により合計が一致しない項目もある。</p>
        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`